<template>
  <fragment>
    <v-card>
      <v-card-text class="py-9 px-8">
        <div class="invoice-header d-flex flex-wrap justify-space-between flex-column flex-sm-row">
          <!-- ADDRESS -->
          <div class="mb-8 mb-sm-0">
            <div class="d-flex align-center">
              <span class="text--primary font-weight-bold text-xl">
                {{ config.name }}
              </span>
            </div>
            <span
              v-if="config.data_bancaria"
              class="d-block"
            >
              <span v-if="config.data_bancaria.info_fiscal">
                <span v-if="config.data_bancaria.info_fiscal.address">
                  <span
                    v-for="(address, indA) in config.data_bancaria.info_fiscal.address"
                    :key="indA"
                  >
                    {{ `${address.address}, ` }}
                  </span>
                </span>
                <span v-if="config.data_bancaria.info_fiscal.localidad">{{
                  `${config.data_bancaria.info_fiscal.localidad}, `
                }}</span>
                <span v-if="config.data_bancaria.info_fiscal.code_postal">{{
                  `${config.data_bancaria.info_fiscal.code_postal}, `
                }}</span>
                <span v-if="config.data_bancaria.info_fiscal.country">{{
                  `${config.data_bancaria.info_fiscal.country}`
                }}</span>
              </span>
              <template v-if="config.data_bancaria.cif_nif_facturation">
                <span class="d-block">NIT: {{ config.data_bancaria.cif_nif_facturation }}</span>
              </template>
            </span>
            <span
              v-if="emailPrincipal"
              class="d-block"
            > Email: {{ emailPrincipal }} </span>
            <span
              class="d-block"
            ><b>{{ $t('lbl.cotization') }}: </b>{{ model.code }}</span>
          </div>
          <!-- ISOTIPO -->
          <div>
            <p class="font-weight-medium text-xl text--primary mb-4">
              <v-img
                :src="`${url_public}${config.logo ? config.logo : config.isotipo}`"
                max-height="250px"
                max-width="250px"
                alt="logo"
                contain
                class="me-3 "
              ></v-img>
            </p>
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>

      <!-- Payment Details -->
      <v-card-text class="px-8">
        <v-simple-table class="purchased-items-table pb-8">
          <template v-slot:default>
            <tbody>
              <tr>
                <td colspan="2">
                  <b>Cliente:</b>
                  <fragment v-if="model.cliente">
                    {{ `${model.cliente.name} ${model.cliente.apellidos ? model.cliente.apellidos : ''}` }}
                  </fragment>
                </td>
              </tr>
              <tr>
                <td><b>Fecha:</b> {{ $moment(model.data_shop).format('D-MMM-YY') }}</td>
                <td><b>Hora:</b> {{ $moment(model.data_shop).format('HH:mm') }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <!-- items cartshop -->
        <v-simple-table class="purchased-items-table mt-5">
          <template v-slot:default>
            <thead>
              <tr>
                <th>
                  Item / Servicio
                </th>
                <th style="width:200px;">
                  Fecha
                </th>
                <th style="width:100px;">
                  Precio
                </th>
              </tr>
            </thead>
            <tbody style="font-size: 10px !important;">
              <template v-for="(carsIt, ind) in carsItems">
                <fragment
                  v-if="carsIt.entity === 'cars'"
                  :key="ind"
                >
                  <tr v-if="carsItems.length > 0">
                    <td colspan="2">
                      <b>Renta de Autos</b>
                    </td>
                    <td class="text-no-wrap">
                      <b>{{ priceAllCars | currency(symbolCurrency) }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ carsIt.data_service.items.car.name }}
                      (Días:{{ carsIt.data_markups.diasReservar }})
                      <br />
                      Recogida: {{ carsIt.data_service.recogida.nameComodin }}<br />
                      Entrega: {{ carsIt.data_service.entrega.nameComodin }}<br />
                      <div
                        v-if="carsIt.data_service.items.suplementos.length > 0"
                        class="ml-4"
                      >
                        <b>Suplementos</b><br />
                        <span
                          v-for="(suplemento, indS) in carsIt.data_service.items.suplementos"
                          :key="indS"
                        >
                          <span v-if="suplemento.check"> {{ suplemento.suplemento.name }}<br /> </span>
                        </span>
                      </div>
                    </td>
                    <td>
                      .<br />
                      {{ $moment(carsIt.data_service.dateRecogida).format('D-MMM-YY') }}
                      a
                      {{ $moment(carsIt.data_service.dateRecogida).format('HH:mm') }}
                      <br />
                      {{ $moment(carsIt.data_service.dateEntrega).format('D-MMM-YY') }}
                      a
                      {{ $moment(carsIt.data_service.dateEntrega).format('HH:mm') }}<br />
                      <fragment v-if="carsIt.data_service.items.suplementos.length > 0">
                        <br />
                        <fragment
                          v-for="(suplemento, indS) in carsIt.data_service.items.suplementos"
                          :key="indS"
                        >
                          <fragment v-if="suplemento.check">
                            <br />
                          </fragment>
                        </fragment>
                      </fragment>
                    </td>
                    <td>
                      {{ carsIt.data_markups.priceTotal | currency(symbolCurrency) }}
                      <fragment v-if="carsIt.data_service.items.suplementos.length > 0">
                        <br />
                        <br />
                        <br />
                        <br />
                        <span
                          v-for="(suplemento, indS) in carsIt.data_service.items.suplementos"
                          :key="indS"
                        >
                          <span v-if="suplemento.check"> {{ suplemento.priceAll | currency(symbolCurrency) }}<br /> </span>
                        </span>
                      </fragment>
                    </td>
                  </tr>
                </fragment>
                <fragment
                  v-if="carsIt.entity === 'hotels'"
                  :key="ind"
                >
                  <tr v-if="carsItems.length > 0">
                    <td colspan="2">
                      <b>Alojamiento</b>
                    </td>
                    <td class="text-no-wrap">
                      <b>{{ priceAllCars | currency(symbolCurrency) }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ carsIt.data_service.items.hotel.name }}
                      (Días:{{ carsIt.data_markups.diasReservar }})
                      <br />
                      Desde:<br />
                      Hasta:<br />
                      <!--<div
                        v-if="carsIt.data_service.items.suplementos.length > 0"
                        class="ml-4"
                      >
                        <b>Suplementos</b><br />
                        <span
                          v-for="(suplemento, indS) in carsIt.data_service.items.suplementos"
                          :key="indS"
                        >
                          <span v-if="suplemento.check"> {{ suplemento.suplemento.name }}<br /> </span>
                        </span>
                      </div>-->
                    </td>
                    <td>
                      .<br />
                      {{ $moment(carsIt.data_service.dateIn).format('D-MMM-YY') }}
                      <!--a
                      {{ $moment(carsIt.data_service.dateIn).format('HH:mm') }}-->
                      <br />
                      {{ $moment(carsIt.data_service.dateOut).format('D-MMM-YY') }}
                      <!--a
                      {{ $moment(carsIt.data_service.dateOut).format('HH:mm') }}--><br />
                      <!--<fragment v-if="carsIt.data_service.items.suplementos.length > 0">
                        <br />
                        <fragment
                          v-for="(suplemento, indS) in carsIt.data_service.items.suplementos"
                          :key="indS"
                        >
                          <fragment v-if="suplemento.check">
                            <br />
                          </fragment>
                        </fragment>
                      </fragment>-->
                    </td>
                    <td>
                      {{ carsIt.data_markups.priceTotal | currency(symbolCurrency) }}
                      <fragment v-if="carsIt.data_service.items.suplementos.length > 0">
                        <br />
                        <br />
                        <br />
                        <br />
                        <span
                          v-for="(suplemento, indS) in carsIt.data_service.items.suplementos"
                          :key="indS"
                        >
                          <span v-if="suplemento.check"> {{ suplemento.priceAll | currency(symbolCurrency) }}<br /> </span>
                        </span>
                      </fragment>
                    </td>
                  </tr>
                </fragment>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>

      <!-- Total -->
      <v-card-text
        class="py-9 px-8"
      >
        <div class="invoice-total d-flex justify-space-between flex-column flex-sm-row">
          <div class="mb-2 mb-sm-0"></div>
          <div v-if="!isLoading">
            <table class="w-full">
              <tr>
                <td class="pe-16">
                  <b>Total a pagar</b>
                  <span v-if="model.ctas_bancarias.moneda"> ({{ model.ctas_bancarias.moneda }})</span>:
                </td>
                <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                  {{ priceAllReserve | currency(symbolCurrency) }}
                </th>
              </tr>
            </table>
          </div>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="px-8 py-6">
        <table class="w-full">
          <tr>
            <td class="pe-16">
              <table style="width: 100%;padding: 10px;background-color: #eceee6">
                <!--<tr>
                      <td class="pe-16">
                        <b>Forma de pago: </b>
                      </td>
                    </tr>-->
                <tr>
                  <td class="pe-16">
                    <b>Banco: </b>
                    <span v-if="model.ctas_bancarias.name_banco">{{ model.ctas_bancarias.name_banco }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="pe-16">
                    <b>Cuenta: </b>
                    <span v-if="model.ctas_bancarias.no_cuenta_bancaria">{{
                      model.ctas_bancarias.no_cuenta_bancaria
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="pe-16">
                    <b>Moneda: </b>
                    <span v-if="model.ctas_bancarias.moneda">{{ model.ctas_bancarias.moneda }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="pe-16">
                    <b>Swift: </b>
                    <span v-if="model.ctas_bancarias.swift">{{ model.ctas_bancarias.swift }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="pe-16">
                    <b>Beneficiario: </b>
                    <span v-if="model.ctas_bancarias.name_fiscal_facturation">{{
                      model.ctas_bancarias.name_fiscal_facturation
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="pe-16">
                    <b>Dirección: </b>
                    <span v-if="model.ctas_bancarias.direction_facturation">{{
                      model.ctas_bancarias.direction_facturation
                    }}</span>
                  </td>
                </tr>
              </table>
            </td>
            <td style="width:100px;"></td>
            <td style="width:200px;">
              <v-img
                :src="`${url_public}${config.cunno ? config.cunno : config.isotipo}`"
                max-height="150px"
                max-width="150px"
                alt="logo"
                contain
                class="me-3 "
              ></v-img>
            </td>
          </tr>
        </table>
      </v-card-text>

      <v-card-text>
        <!-- atencion -->
        <table class="cardBackMine2">
          <tr>
            <td>
              <img
                width="15"
                :src="`${url_public}icons/attention.png`"
              />
              <b>¡Atención!</b><br />
              <span style="font-size: 10px;text-align: justify">
                Los servicios incluidos en esta Cotización quedan sujetos a disponibilidad y no constituyen una reserva
                conﬁrmada. De igual manera los precios pueden estar sujetos a modiﬁcaciones dependiendo del tipo de
                servicio, fechas, cantidades de personas y condiciones. La Cotización será válida por 72 horas.
              </span>
            </td>
          </tr>
        </table>
      </v-card-text>

      <v-divider></v-divider>
    </v-card>
  </fragment>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiChevronLeft, mdiEyeOutline, mdiEyeOffOutline, mdiSendOutline, mdiCurrencyUsd } from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
import themeConfig from '@themeConfig'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    config: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    model: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    emailPrincipal: {
      type: String,
    },
    // eslint-disable-next-line vue/require-default-prop
    carsItems: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    priceAllCars: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    priceAllReserve: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    currencies: {
      type: Array,
    },
  },
  data() {
    return {
      isLoading: true,
      url_public: process.env.VUE_APP_API_URL_PLUBLIC,
      name_project: process.env.VUE_APP_PROJ_NAME,
      emailSoporte: process.env.VUE_APP_SUPORT,
      phoneSoporte: process.env.VUE_APP_PHONE_SUPORT,
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      symbolCurrency: '$',
      icons: {
        mdiChevronLeft,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiSendOutline,
        mdiCurrencyUsd,
      },
    }
  },
  created() {
    if (this.model.car_shop.length > 0) {
      if (this.model.car_shop[0].data_service.items.currencyCode) {
        this.symbolCurrency = this.model.car_shop[0].data_service.items.currencyCode
      }
      this.isLoading = false
    } else {
      this.isLoading = false
    }

    /* this.currencies.forEach(element => {
      if (element.code === this.model.ctas_bancarias.moneda) {
        this.symbolCurrency = element.symbol
      }
    }) */
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

@media print {
  .v-application {
    background: none !important;
  }

  .app-navigation-menu,
  .v-app-bar,
  .v-footer,
  .product-buy-now,
  .app-invoice-preview.row .col-12:last-of-type {
    display: none !important;
  }

  .app-invoice-preview.row .col-12:first-child {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .v-main,
  .app-content-container {
    padding: 0px !important;
  }

  .v-card {
    box-shadow: none !important;
  }

  .app-invoice-preview {
    .invoice-header,
    .payment-details,
    .invoice-total {
      &.d-flex {
        flex-direction: row !important;
      }
    }
  }
}

.containerSizeMine {
  height: max-content;
  bottom: 10px;
}

.posMine {
  position: absolute;
  bottom: 20px;
}

.posFlexMine {
  display: inline-flex;
}

.posBlockMine {
  display: inline-block;
}

.marginGenMine {
  margin: 10px 50px;
}

.marginRightMine {
  margin-right: 20px;
}

.marginRightMine50px {
  margin-right: 50px;
}

.font {
  font-family: Inter, sans-serif;
  line-height: 1.5;
  margin: 0;
}

.h2 {
  font-size: 24px;
  font-weight: bold;
}

.h3 {
  font-size: 20px;
  font-weight: bold;
}

.blackTextMine {
  color: black;
}

.grayTextMine {
  color: rgba(94, 86, 105, 0.68) !important;
}

.h1 {
  font-size: 30px;
  font-weight: bold;
}

.Mine10px {
  font-size: 10px;
}

.Mine14px {
  font-size: 12px;
}

.Mine18px {
  font-size: 14px;
}

.bold {
  font-weight: bolder;
}

.marginMine {
  margin: 0px;
  margin-bottom: 10px !important;
}

.secWidthMine {
  width: auto;
}

.secWidthMine2 {
  width: 180px;
}

.cardBackMine {
  background-color: #eceef4;
  padding: 20px;
}

.cardBackMine2 {
  background-color: #fae49a;
  padding: 20px;
}
</style>
